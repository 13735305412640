import { classNames, dateLocalizedText } from '@/lib/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { parseISO } from 'date-fns';
import { forwardRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { MdEvent } from 'react-icons/md';

import clsx from 'clsx';
import {
	de,
	enCA,
	enGB,
	enZA,
	es,
	fr,
	it,
	pl,
	pt,
	ptBR,
} from 'date-fns/locale';

registerLocale('es', es);
registerLocale('es-MX', es);
registerLocale('pt', pt);
registerLocale('pt-BR', ptBR);
registerLocale('it', it);
registerLocale('pl', pl);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('en-ZA', enZA);
registerLocale('en-CA', enCA);
registerLocale('en-GB', enGB);

export function DatePickerField({
	language,
	label,
	placeholder,
	initialValue,
	onSelect,
	dateInline,
	compact,
}) {
	const [selectedDate, setSelectedDate] = useState(
		initialValue ? parseISO(initialValue) : null
	);

	const onChangeHandler = (date) => {
		setSelectedDate(date);
		onSelect(date);
	};

	return (
		<div
			className={classNames(
				'relative',
				dateInline &&
					'rounded-md border border-gray-300 bg-white px-1 shadow-sm'
			)}
		>
			<DatePicker
				inline={dateInline}
				locale={language}
				selected={selectedDate}
				onChange={(date) => onChangeHandler(date)}
				dateFormat="iii, MMM dd"
				placeholderText={placeholder}
				minDate={new Date()}
				startDate={selectedDate}
				popperClassName="react-datepicker-left"
				customInput={
					<DatepickerCustomInput compact={compact} type="text" label={label} />
				}
				renderCustomHeader={({
					date: selectedDate,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled,
				}) => (
					<div className="flex items-center justify-between px-2 py-3">
						<span className="text-lg capitalize text-gray-700">
							{dateLocalizedText({
								date: selectedDate,
								locale: language,
								pattern: 'MMMM yyyy',
							})}
						</span>

						<div className="space-x-2">
							<button
								onClick={decreaseMonth}
								disabled={prevMonthButtonDisabled}
								type="button"
								className={`${
									prevMonthButtonDisabled && 'cursor-not-allowed opacity-50'
								} inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-0`}
							>
								<ChevronLeftIcon className="h-5 w-5 text-gray-600" />
							</button>

							<button
								onClick={increaseMonth}
								disabled={nextMonthButtonDisabled}
								type="button"
								className={`${
									nextMonthButtonDisabled && 'cursor-not-allowed opacity-50'
								} inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-0`}
							>
								<ChevronRightIcon className="h-5 w-5 text-gray-600" />
							</button>
						</div>
					</div>
				)}
			/>
		</div>
	);
}

const CustomInput = function (props, ref) {
	const inputProps = { ...props };
	delete inputProps.compact;

	return (
		<>
			<label htmlFor={props.label} className="sr-only">
				{props.label}
			</label>
			<div className="relative">
				<div className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-4 focus:outline-none">
					<MdEvent
						className={clsx(
							'text-gray-500',
							props.compact ? 'h-4 w-4' : 'h-5 w-5'
						)}
						aria-hidden="true"
					/>
				</div>
				<input
					{...inputProps}
					ref={ref}
					id={props.label}
					readOnly
					className={clsx(
						'block w-full rounded-full border-0 py-3 pl-12 pr-6 text-base font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:font-medium placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
						props.compact && 'md:py-2 md:pr-4 md:pl-10 md:text-sm'
					)}
				/>
			</div>
		</>
	);
};

export const DatepickerCustomInput = forwardRef(CustomInput);
