import { Autocomplete } from '@/components/ui/search/Autocomplete';
import { DatePickerField } from '@/components/ui/search/DatePickerField';
import {
	GA_ROUTING_MAP,
	bookingCityLink,
	classNames,
	fetcher,
} from '@/lib/utils';
import { Switch } from '@headlessui/react';
import { ArrowsUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { format, isBefore, parseISO, startOfDay } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { event } from 'nextjs-google-analytics';
import { useState } from 'react';
import { MdPlace, MdSearch, MdTripOrigin } from 'react-icons/md';
import { i18nRoute } from 'routes';
import useSWR from 'swr';

export function SearchForm({
	initialSearch = {},
	className,
	searchAccomodationsEnabled = false,
	dateDisabledMobile = false,
	dateInline,
	compact,
	darkButton,
	onSearch,
	switchLocationsEnabled,
}) {
	const router = useRouter();

	const { t } = useTranslation('common');

	if (initialSearch && initialSearch.date) {
		const routeDate = startOfDay(parseISO(initialSearch.date));
		if (isBefore(routeDate, startOfDay(new Date()))) {
			initialSearch.date = format(new Date(), 'yyyy-LL-dd');
		}
	}

	const [accomodationsEnabled, setAccomodationsEnabled] = useState(true);
	const [search, setSearch] = useState(initialSearch);

	const { data: origins } = useSWR(
		`/api/origins?language=${router.locale}`,
		fetcher,
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	);

	const { data: destinations } = useSWR(
		() =>
			`/api/destinations?originId=${search.origin.id}&language=${router.locale}`,
		fetcher,
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	);

	const handleSelectedOrigin = (place) => {
		setSearch({ ...search, origin: place });
		if (place) {
			const eventParams = {
				category: GA_ROUTING_MAP[router.pathname],
				label: place.longName,
			};
			event('AutoComplete_SELECTED_ORIGIN', eventParams);
		}
	};

	const handleSelectedDestination = (place) => {
		setSearch({ ...search, destination: place });
		if (place) {
			const eventParams = {
				category: GA_ROUTING_MAP[router.pathname],
				label: place.longName,
			};
			event('AutoComplete_SELECTED_DESTINATION', eventParams);
		}
	};

	const handleSelectedDate = (date) => {
		const selectedDate = format(date, 'yyyy-LL-dd');
		setSearch({ ...search, date: selectedDate });
		const eventParams = {
			category: GA_ROUTING_MAP[router.pathname],
			label: 'SELECTED',
		};
		event('Datepicker', eventParams);
	};

	const handleSwitchLocations = () => {
		setSearch((prevSearch) => {
			return {
				...prevSearch,
				origin: prevSearch.destination,
				destination: prevSearch.origin,
			};
		});
		const eventParams = {
			category: GA_ROUTING_MAP[router.pathname],
			label: 'Click',
		};
		event('Search_Form_Modal__switch-locations', eventParams);
	};

	const submitHandler = (evt) => {
		evt.preventDefault();

		if (!search.origin) {
			const eventParams = {
				category: GA_ROUTING_MAP[router.pathname],
				label: 'Empty origin',
			};
			event('AutoComplete_INVALID_ORIGIN', eventParams);
			return;
		}
		if (!search.destination) {
			const eventParams = {
				category: GA_ROUTING_MAP[router.pathname],
				label: 'Empty destination',
			};
			event('AutoComplete_INVALID_DESTINATION', eventParams);
			return;
		}

		let url = i18nRoute(`/tickets/[routeId]`, router.locale, {
			routeId: `${search.origin.slug}-x-${search.destination.slug}`,
		});

		if (search.date) {
			url += `?outbound_date=${search.date}`;
		} else {
			url += `?outbound_date=${format(new Date(), 'yyyy-LL-dd')}`;
		}

		const eventParams = {
			category: GA_ROUTING_MAP[router.pathname],
			label: 'SELECTED_ALL',
		};
		event('AutoComplete_FORM_SUBMITED', eventParams);

		if (searchAccomodationsEnabled && accomodationsEnabled) {
			const eventParams = {
				category: GA_ROUTING_MAP[router.pathname],
				label: search.destination.shortName,
			};
			event('search_form_booking', eventParams);

			const newTab = window.open();
			newTab.location.href = url;

			window.location.href = bookingCityLink({
				language: router.locale,
				cityName: search.destination.shortName,
				checkIn: search.date,
			});
		} else {
			router.push(url, null, { locale: router.locale });
		}

		if (onSearch && typeof onSearch === 'function') {
			onSearch();
		}
	};

	const searchButtonClasses = {
		primaryButton:
			'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600',
		darkButton: 'bg-gray-900 hover:bg-gray-700 focus-visible:outline-gray-900',
	};

	return (
		<>
			<form
				onSubmit={submitHandler}
				className={classNames(
					className,
					'flex flex-col gap-y-2 sm:flex-row sm:gap-x-2'
				)}
			>
				<div>
					<Autocomplete
						key={search?.origin?.shortName}
						Icon={MdTripOrigin}
						compact={compact}
						label={t('common:searchForm.origin.label')}
						placeholder={t('common:searchForm.origin.placeholder')}
						initialValue={search.origin}
						initialOptions={origins}
						language={router.locale}
						onSelect={handleSelectedOrigin}
					/>
				</div>
				{switchLocationsEnabled && (
					<div className="relative -mt-1.5 flex items-center md:my-auto">
						<button
							type="button"
							onClick={() => handleSwitchLocations()}
							className={clsx(
								'absolute right-8 top-auto -bottom-6 z-10 flex h-12 w-12 items-center justify-center rounded-full border border-gray-400 bg-white text-gray-600',
								'md:relative md:bottom-auto md:right-auto md:z-0 md:my-auto md:h-auto md:w-auto md:rotate-90 md:border-none md:bg-transparent'
							)}
						>
							<ArrowsUpDownIcon className="h-6 w-6" />
						</button>
					</div>
				)}
				<div>
					<Autocomplete
						key={search?.destination?.shortName}
						Icon={MdPlace}
						compact={compact}
						label={t('common:searchForm.destination.label')}
						placeholder={t('common:searchForm.destination.placeholder')}
						initialValue={search.destination}
						initialOptions={destinations}
						searchContext={search?.origin?.countryCode}
						language={router.locale}
						onSelect={handleSelectedDestination}
					/>
				</div>
				<div className={classNames(dateDisabledMobile && 'hidden md:block')}>
					<DatePickerField
						compact={compact}
						label={t('common:searchForm.departureDate.label')}
						placeholder={t('common:searchForm.departureDate.placeholder')}
						initialValue={search.date || null}
						onSelect={handleSelectedDate}
						language={router.locale}
						dateInline={dateInline}
						pathname={router.pathname}
					/>
				</div>
				<div className="flex shrink-0 justify-center">
					<button
						type="submit"
						className={classNames(
							'inline-flex w-full items-center justify-center gap-x-3 rounded-full px-4 py-3 font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  lg:max-w-none lg:px-6',
							compact && 'md:py-2 md:text-sm',
							darkButton
								? searchButtonClasses['darkButton']
								: searchButtonClasses['primaryButton']
						)}
					>
						<MdSearch className="-ml-0.5 h-5 w-5" aria-hidden="true" />
						<span className="font-medium">
							{t('common:searchForm.searchButton.label')}
						</span>
					</button>
				</div>
			</form>
			{searchAccomodationsEnabled && (
				<Switch.Group as="div" className="mt-4 flex items-center pl-2">
					<Switch
						checked={accomodationsEnabled}
						onChange={setAccomodationsEnabled}
						className={classNames(
							accomodationsEnabled ? 'bg-indigo-600' : 'bg-gray-200',
							'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
						)}
					>
						<span
							aria-hidden="true"
							className={classNames(
								accomodationsEnabled ? 'translate-x-5' : 'translate-x-0',
								'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
							)}
						/>
					</Switch>
					<Switch.Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900">
							{t('common:hotels.findMyAccomodation')}
						</span>
					</Switch.Label>
				</Switch.Group>
			)}
		</>
	);
}
